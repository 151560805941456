import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "./components/Loader";
// import Example from "./components/Faizan";
const Signup = lazy(() => import("./pages/Signup"));
const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Contactus = lazy(() => import("./pages/Contactus"));
const ForgetPassword = lazy(() => import("./pages/ForgetPassword"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const Mycompain = lazy(() => import("./pages/Addcompain"));
const ActiveCompain = lazy(() => import("./pages/ActiveCompain"));
const GenerateVocher = lazy(() => import("./pages/GenerateVocher"));
const QrScanner = lazy(() => import("./pages/QrScanner"));
const VerifedSuccessfull = lazy(() =>
  import("./components/VerifedSuccessfull")
);

function App() {
  return (
    <div className="mt-9">
      {/* <Example/> */}
      <Routes>
        <Route
          path="/signup"
          element={
            <Suspense fallback={<Loader />}>
              <Signup />
            </Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/forgetPassword"
          element={
            <Suspense fallback={<Loader />}>
              <ForgetPassword />
            </Suspense>
          }
        />
        <Route
          path="/changepassowrd"
          element={
            <Suspense fallback={<Loader />}>
              <ChangePassword />
            </Suspense>
          }
        />

        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<Loader />}>
              <About />
            </Suspense>
          }
        />
        <Route
          path="/Contactus"
          element={
            <Suspense fallback={<Loader />}>
              <Contactus />
            </Suspense>
          }
        />

        <Route path="/add-Campaigns">
          <Route
            index
            element={
              <Suspense fallback={<Loader />}>
                <Mycompain />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/active-Campaigns"
          element={
            <Suspense fallback={<Loader />}>
              <ActiveCompain />
            </Suspense>
          }
        />
        <Route
          path="/Verify-voucher"
          element={
            <Suspense fallback={<Loader />}>
              <QrScanner />
            </Suspense>
          }
        />
        <Route
          path="/VerifedSuccessfull"
          element={
            <Suspense fallback={<Loader />}>
              <VerifedSuccessfull />
            </Suspense>
          }
        />
        <Route
          path="/generate-voucher"
          element={
            <Suspense fallback={<Loader />}>
              <GenerateVocher />
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
